.Streamline_Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
}

.Streamline_Icon_Animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    transform-origin: center;
    animation-timing-function: linear;

}

.Streamline_Icon_Spin {
    animation-name: rotate;
    animation-duration: 2s;
}

.Streamline_Animation_Infinite {
    animation-iteration-count: infinite;
}

.Streamline_Animation_Fast {
    animation-duration: 1s;
}

.Streamline_Animation_EaseInOut {
    animation-timing-function: ease-in-out;
}

@keyframes rotate {

    100% {
        transform: rotate(360deg);
    }
}
